<template>
  <div>
    <div v-if="!isApp" class="tabbar" :class="navBarFixed ? 'navBarFixed' : ''">
      <img @click="goback" src="../../../../static/common/logo.png" alt="" />
    </div>
    <div class="banner">
      <img src="/subject/live2.0/wap/banner.png" alt="">
    </div>
    <div class="content">
      <div class="part">
        <img class="partImg" src="/subject/live2.0/wap/part1.png" alt="">
        <div class="positionBox positionBox1">
          <img @click="goLive" src="/subject/live2.0/wap/classBtn.png" alt="">
          <img @click="goRoom" src="/subject/live2.0/wap/into.png" alt="">
        </div>
        <!-- <img class="text" src="/subject/live2.0/wap/text.png" alt=""> -->
      </div>
      <div class="part">
        <img class="partImg" src="/subject/live2.0/wap/part2.png" alt="">
        <div class="positionBox">
          <img @click="goLive" src="/subject/live2.0/wap/live.png" alt="">
          <img @click="goRoom" src="/subject/live2.0/wap/into.png" alt="">
        </div>
        <img class="text" src="/subject/live2.0/wap/text.png" alt="">
      </div>
      <div class="part">
        <img class="partImg" src="/subject/live2.0/wap/part3.png" alt="">
        <!-- <img @click="goLive" class="yuyue" src="/subject/live2.0/wap/yuyue.png" alt=""> -->
        <div class="positionBox positionBox1">
          <img @click="goLive" src="/subject/live2.0/wap/classBtn.png" alt="">
          <img @click="goRoom" src="/subject/live2.0/wap/into.png" alt="">
        </div>
        <!-- <img class="text" src="/subject/live2.0/wap/text.png" alt=""> -->
      </div>
    </div>
    <contactusVue v-if="!isApp"></contactusVue>
  </div>
</template>

<script>
import ContactusVue from "../../Contactus.vue";
export default {
  name:'live2.0Wap',
  components:{
    ContactusVue,
  },
  data(){
    return{
      navBarFixed: false,
      scroll: '',
      isApp: false,
      screenWidth: null,
    }
  },
  created(){
    this.$emit("controlShow", true);
    if(this.$route.query.isApp){
      this.isApp = true;
    };
  },
  mounted(){
    window.addEventListener("scroll", this.handleScroll);
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  watch: {
    screenWidth: function (n) {
      if (n > 500) {
        this.$router.push({
          name: "live2.0Pc",
        });
      }
    },
  },
  methods:{
    // 返回上级页面
    goback(){
      window.location.href = window.location.origin;
    },
    // 控制顶部的定位
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if(this.scroll > 90 ){
        this.navBarFixed = true;
      }else{
        this.navBarFixed = false;
      }
    },
    goLive(){
      window.open(this.$parent.live800UrlUc)
    },
    goRoom(){
      this.$router.push({
        name:'classRoom'
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .tabbar {
    width: 100%;
    background-color: #ffffff;
    img {
      margin: 10px 30px;
      width: 130px;
    }
  }
  .navBarFixed {
    position: fixed;
    top: 0;
    z-index: 999;
  }
  .banner{
    width: 100%;
    img{
      width: 100%;
      display: block;
    }
  }
  .content{
    width: 100%;
    height: auto;
    background: url("/subject/live2.0/wap/background.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 30px;
    .part{
      width: 100%;
      text-align: center;
      position: relative;
      margin-top: 8px;
      .partImg{
        width: 90%;
      }
      .positionBox{
        width: 75%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%,-50%);
        img{
          width: 125px;
          cursor: pointer;
        }
      }
      .positionBox1{
        bottom: 0px;
      }
      .text{
        width: 113px;
        position: absolute;
        bottom: 12px;
        left: 14.5%;
      }
      .yuyue{
        width: 96px;
        position: absolute;
        bottom: 11.2%;
        left: 20%;
        cursor: pointer;
      }
    }
  }
</style>